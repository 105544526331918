<template>
  <div>
    <!-- header row -->
    <b-row class="content-header">
      <b-col cols="12">
        <div class="breadcrumb-wrapper">
          <b-breadcrumb>
            <b-breadcrumb-item :to="{ name: 'dashboard' }">
              <feather-icon
                icon="HomeIcon"
                size="16"
                class="align-text-top"
              /> Dashboard
            </b-breadcrumb-item>
            <b-breadcrumb-item :to="{ name: 'browse-winners' }">
              Winners
            </b-breadcrumb-item>
            <b-breadcrumb-item :active="true">
              Manage Winners
            </b-breadcrumb-item>
          </b-breadcrumb>
        </div>
      </b-col>
      <b-col md="6">
        <h2 class="p-2">
          <feather-icon
            icon="AwardIcon"
            size="24"
          />
          Manage Winners
        </h2>
      </b-col>
      <b-col
        md="6"
        class="mb-2 text-right pt-2"
      >
        <!-- <b-button
          variant="primary"
          :to="{ name: 'create-winner' }"
        >
          <feather-icon
            icon="PlusIcon"
            size="16"
            class="align-text-top"
          />  Add New
        </b-button> -->
      </b-col>
    </b-row>

    <!-- error message -->
    <section v-if="errorMessage">
      <b-card>
        <b-alert
          variant="danger"
          show
        >
          <div class="alert-body">
            {{ errorMessage }}
          </div>
        </b-alert>
      </b-card>
    </section>

    <!-- winners table -->
    <section v-if="visibleWinners">
      <!-- table row  -->
      <b-card>
        <b-row v-if="visibleWinners.length <= 0">
          <b-col>
            No items to show.
          </b-col>
        </b-row>
        <b-row v-if="visibleWinners.length > 0">
          <b-col
            md="2"
            sm="4"
            class="my-1"
          >
            <b-form-group class="mb-0">
              <label
                class="d-block text-sm-left"
                for="perPageSelect"
              >Per page</label>
              <b-form-select
                id="perPageSelect"
                v-model="perPage"
                size="sm"
                :options="pageOptions"
                class="w-50"
                @change="handlePerPage"
              />
            </b-form-group>
          </b-col>

          <b-col
            md="4"
            sm="8"
            offset="6"
            class="my-1"
          >
            <b-form-group class="mb-0">
              <label
                class="d-block text-sm-left"
                for="sortBySelect"
              >Sort</label>
              <b-input-group size="sm">
                <b-form-select
                  id="sortBySelect"
                  v-model="sortBy"
                  :options="sortOptions"
                  class="w-75"
                >
                  <template v-slot:first>
                    <option value="">
                      Select
                    </option>
                  </template>
                </b-form-select>
                <b-form-select
                  v-model="sortDesc"
                  size="sm"
                  :disabled="!sortBy"
                  class="w-25"
                >
                  <option :value="false">
                    Asc
                  </option>
                  <option :value="true">
                    Desc
                  </option>
                </b-form-select>
              </b-input-group>
            </b-form-group>
          </b-col>

          <!-- <b-col
            md="6"
            class="my-1"
          >
            <b-form-group class="mb-0">
              <label
                class="d-block text-sm-left"
                for="filterInput"
              >Filter</label>
              <b-input-group size="sm">
                <b-form-input
                  id="filterInput"
                  v-model="filter"
                  type="search"
                  placeholder="Type to Search"
                />
                <b-input-group-append>
                  <b-button
                    :disabled="!filter"
                    @click="filter = ''"
                  >
                    Clear
                  </b-button>
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
          </b-col> -->

          <!--
              :per-page="perPage"
              :current-page="currentPage"
          -->
          <b-col cols="12 mt-2">
            <b-table
              hover
              responsive
              :items="visibleWinners"
              :fields="fields"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              :sort-direction="sortDirection"
              :filter="filter"
              :filter-included-fields="filterOn"
              @filtered="onFiltered"
            >
              <template #cell(cohort_name)="data">
                {{ data.item.cohort_level.name || '(no name)' }}
              </template>

              <template #cell(winner_name)="data">
                <b-link
                  @click="handleItemView(data.item)"
                >
                  {{ `${data.item.user.first_name} ${data.item.user.last_name}` }}
                </b-link>
              </template>

              <template #cell(winner_email)="data">
                {{ `${data.item.user.email}` }}
              </template>

              <template #cell(awarded)="data">
                <b-badge
                  pill
                  :variant="closedStatusColor[data.item.awarded] || 'light-secondary'"
                >
                  <span v-if="data.item.awarded">Awarded</span>
                  <span v-else>Awaiting</span>
                </b-badge>
              </template>

              <template #cell(share_pennies)="data">
                {{ getReadableDollarAmount(data.item.share_pennies) }}
              </template>

              <template #cell(actions)="data">
                <span>
                  <b-dropdown
                    variant="link"
                    toggle-class="text-decoration-none"
                    no-caret
                  >
                    <template v-slot:button-content>
                      <feather-icon
                        icon="MoreVerticalIcon"
                        size="16"
                        class="text-body align-middle mr-25"
                      />
                    </template>

                    <b-dropdown-item
                      @click="handleItemView(data.item)"
                    >
                      <feather-icon
                        icon="EyeIcon"
                        class="mr-50"
                      />
                      <span>View Winner</span>
                    </b-dropdown-item>

                    <b-dropdown-item
                      @click="handleItemAwarded(data.item.id)"
                    >
                      <feather-icon
                        icon="CheckCircleIcon"
                        class="mr-50"
                      />
                      <span>Set as Awarded</span>
                    </b-dropdown-item>
                  </b-dropdown>
                </span>
              </template>

            </b-table>
          </b-col>

          <b-col
            cols="12"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalTableRows"
              :per-page="perPage"
              align="center"
              size="sm"
              class="my-0"
              @page-click="handlePaginationClick"
            />
          </b-col>
        </b-row>
      </b-card>
    </section>

    <!-- view modal -->
    <b-modal
      id="modal-view-winner"
      centered
      title="View Winner"
      size="lg"
      hide-footer
    >
      <b-card-text v-if="winnerForm">
        <b-row
          v-if="winnerForm.user"
        >
          <!-- winner first -->
          <b-col md="6">
            <b-form-group
              label="Winner First Name"
              label-for="winnerFirstName"
            >
              <b-form-input
                id="winnerFirstName"
                v-model="winnerForm.user.first_name"
                type="text"
                class="form-control"
                placeholder="Winner First Name"
                readonly
              />
            </b-form-group>
          </b-col>

          <!-- winner last -->
          <b-col md="6">
            <b-form-group
              label="Winner Last Name"
              label-for="winnerLastName"
            >
              <b-form-input
                id="winnerLastName"
                v-model="winnerForm.user.last_name"
                type="text"
                class="form-control"
                placeholder="Winner Last Name"
                readonly
              />
            </b-form-group>
          </b-col>

          <!-- winner email -->
          <b-col md="6">
            <b-form-group
              label="Winner Email"
              label-for="winnerEmail"
            >
              <b-form-input
                id="winnerEmail"
                v-model="winnerForm.user.email"
                type="text"
                class="form-control"
                placeholder="Winner Email"
                readonly
              />
            </b-form-group>
          </b-col>

          <!-- winner prize -->
          <b-col md="6">
            <b-form-group
              label="Award Total"
              label-for="winnerPrize"
            >
              <b-form-input
                id="winnerPrize"
                :value="getReadableDollarAmount(winnerForm.share_pennies)"
                type="text"
                class="form-control"
                placeholder="Award Total"
                readonly
              />
            </b-form-group>
          </b-col>

          <!-- award status -->
          <b-col md="6">
            <b-form-group
              label="Award Status"
              label-for="awardStatus"
            >
              <b-form-select
                id="awardStatus"
                v-model="winnerForm.awarded"
                class="form-control"
                readonly
                disabled
                plain
                :options="booleanStatus"
              />
            </b-form-group>
          </b-col>

          <!-- awarded date -->
          <b-col
            v-if="winnerForm.awarded_at"
            md="6"
          >
            <b-form-group
              label="Awarded Date"
              label-for="awardedDate"
            >
              <b-form-input
                id="awardedDate"
                :value="convertToLocalTime(winnerForm.awarded_at)"
                type="text"
                class="form-control"
                placeholder="Awarded Date"
                readonly
              />
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col
            class="mt-2 text-right"
          >
            <!-- cancel button -->
            <b-button
              variant="outline-secondary"
              type="button"
              class="mr-1"
              @click="handleModalCancel"
            >
              Close
            </b-button>

            <!-- action button -->
            <b-button
              variant="primary"
              type="button"
              :disabled="winnerForm.awarded"
              @click="handleItemAwarded(winnerForm.id)"
            >
              {{ winnerForm.awarded ? "Already Awarded" : "Set as Awarded" }}
            </b-button>
          </b-col>
        </b-row>
      </b-card-text>
    </b-modal>
  </div>
</template>

<script>
import {
  BBreadcrumb, BBreadcrumbItem, BRow, BCol,
  BAlert,
  BBadge, BButton,
  BCard, BCardText,
  BDropdown, BDropdownItem,
  BFormInput, BFormGroup, BFormSelect,
  BInputGroup, // BInputGroupAppend,
  BLink,
  BPagination, BTable,
  BModal,
} from 'bootstrap-vue'

import { required } from '@validations'
// import { ValidationProvider, ValidationObserver } from 'vee-validate'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
// import flatPickr from 'vue-flatpickr-component'
import moment from 'moment'
import useJwt from '@/auth/jwt/useJwt'

export default {
  components: {
    BBreadcrumb,
    BBreadcrumbItem,
    BRow,
    BCol,
    BAlert,
    BBadge,
    BButton,
    BCard,
    BCardText,
    BDropdown,
    BDropdownItem,
    BFormInput,
    BFormGroup,
    BFormSelect,
    BInputGroup,
    BLink,
    BPagination,
    BTable,
    BModal,

    // flatPickr,
  },
  data() {
    return {
      userData: JSON.parse(localStorage.getItem('userData')),
      adminAccess: false,
      adminSuffix: '', // 'AsRoot' if root user

      perPage: 10,
      pageOptions: [5, 10, 15, 30, 45, 75],
      // totalRows: 1,
      currentPage: 1,
      sortBy: 'id',
      sortDesc: true,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      fields: [
        { key: 'id', label: 'Id', sortable: true },
        { key: 'cohort_name', label: 'Pool Name', sortable: true },
        { key: 'share_pennies', label: 'Prize', sortable: true },
        { key: 'winner_name', label: 'Winner Name', sortable: true },
        { key: 'winner_email', label: 'Winner Email', sortable: true },
        { key: 'awarded', label: 'Award Status', sortable: true },
        { key: 'actions', label: 'Actions' },
      ],
      statusColor: {
        approved: 'light-success',
        flagged: 'light-warning',
        new: 'light-info',
        rejected: 'light-danger',
      },
      closedStatusColor: {
        false: 'light-danger',
        true: 'light-success',
      },
      booleanStatus: [
        { value: null, text: '' },
        { value: true, text: 'Awarded' },
        { value: false, text: 'Awaiting Award' },
      ],
      errorMessage: null,

      // edit winner form
      winnerForm: {},

      // validation
      required,
    }
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => ({ text: f.label, value: f.key }))
    },
    visibleWinners() {
      const digestData = this.$store.getters['winners/getAllWinners']
      return digestData ? digestData.data : []
    },
    totalTableRows() {
      const digestData = this.$store.getters['winners/getAllWinners']
      const linksData = digestData ? digestData.links : 0
      return linksData ? linksData.current_size * linksData.last_page : 0
    },
  },
  created() {
    this.getWinners()
  },
  methods: {
    getWinners(args) {
      // set table page and size
      const winnerArgs = {
        page: this.currentPage,
        size: this.perPage,
        ...args,
      }

      // get winners digest
      this.$store.dispatch('winners/fetchAllWinners', winnerArgs)
    },

    /* QUESTION MANAGEMENT */

    handleItemAwarded(winnerId) {
      // confirm alert
      this.$swal({
        title: 'Set as Awarded?',
        text: 'This winner will be set to AWARDED.',
        icon: 'winner',
        showCancelButton: true,
        confirmButtonText: 'Confirm',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-secondary ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          useJwt.setWinnerAwarded(
            winnerId,
          )
            .then(response => {
              const responseData = response
              console.log('setWinnerAwarded', responseData)

              // confirm message
              this.$swal({
                icon: 'success',
                title: 'Done!',
                text: 'Your winner has been set as AWARDED.',
                customClass: {
                  confirmButton: 'btn btn-success',
                },
              })
              this.$router.go(0) // reload page
            })
            .catch(error => {
              console.log(error.response.data)
              const errorMessage = error.response.data.error || 'This request did not go through.'

              // error message
              this.$swal({
                icon: 'error',
                title: 'Error',
                text: `${errorMessage}`,
                customClass: {
                  confirmButton: 'btn btn-danger',
                },
              })
            })
        }
      })
    },
    handleItemView(winnerData) {
      this.winnerForm = {
        ...winnerData,
      }
      this.$bvModal.show('modal-view-winner')
    },
    handleModalCancel() {
      this.$bvModal.hide('modal-view-winner')
      this.winnerForm = {}
    },
    handleModalSubmit() {
      const winnerFormData = {
        ...this.winnerForm,
        open_date: this.convertToUTC(this.winnerForm.open_date),
        cutoff_date: this.convertToUTC(this.winnerForm.cutoff_date),
        close_date: this.convertToUTC(this.winnerForm.close_date),
      }
      console.log('winnerFormData', winnerFormData)

      useJwt.updateWinner(winnerFormData.id, winnerFormData)
        .then(res => {
          const responseCode = res.status
          const responseText = res.statusText

          console.log('updateWinner', res)
          console.log('Server reponse: ', responseCode, responseText)
          // this.message = `${responseCode} ${responseText}`
          this.message = `${responseText}`

          // show notification
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Winner Updated',
              icon: 'WinnerPlusIcon',
              variant: 'success',
              text: `You have updated a winner: "${this.winnerForm.text}"`,
            },
          })
          this.$bvModal.hide('modal-view-winner')
          this.$router.go(0) // reload page
        })
        .catch(err => {
          console.log('AddWinner ERROR', err.response.status, err.response.statusText, err.response.data)

          const errorResponse = err.response.data.data
          const errorMessage = errorResponse.error ? errorResponse.error : errorResponse

          // Error notification on failure
          this.$swal({
            title: 'Error',
            text: `${errorMessage}`,
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          })
        })
    },

    /* TABLE FILTER AND PAGINATION */

    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      // this.totalRows = filteredItems.length
      this.perPage = filteredItems.length
      this.currentPage = 1

      this.getWinners()
    },
    handlePaginationClick(bvEvent, currentPage) {
      const conArgs = {
        page: currentPage,
      }
      this.getWinners(conArgs)
    },
    handlePerPage(sizeValue) {
      const conArgs = {
        size: sizeValue,
      }
      this.getWinners(conArgs)
    },

    /* MISCELLANEOUS */

    getReadableDollarAmount(penniesStr) {
      let formattedDollarAmount = '$0'
      if (penniesStr > 0) {
        formattedDollarAmount = (penniesStr / 100).toLocaleString('en-US', { style: 'currency', currency: 'USD' }) // TODO get local currency
      }
      return formattedDollarAmount
    },
    convertToLocalTime(dateStr) {
      // convert utc date to local time
      const utcDate = moment.utc(dateStr)
      const convertedDate = moment(utcDate).local().format('YYYY-MM-DD h:mm A')
      return convertedDate
    },
    convertToUTC(dateStr) {
      // convert date to UTC
      const localDate = moment(dateStr).local()
      const convertedDate = moment.utc(localDate).format('YYYY-MM-DD HH:mm')
      return convertedDate
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
